<template>
    <div class="relative q-pa-xs" :style="`height: ${terminalContainerHeight - 100}px;`">
    <transition-group name="terminal-item">
      <div
          v-for="(item, i) in data.objects"
          :key="`${item.type}:${i}`"
          class="q-mb-sm"
      >
        <component
            :is="$dynamicComponents[item.type]"
            v-bind="{ data: item.data, rawData: item }"
            @click="handleClick"
        />
      </div>

    </transition-group>

    <portal to="settings">
      <tiles :schema="tilesSchema"/>
    </portal>

    <confirm-modal ref="confirmModal" />

    <error-screen :message="error" @reset="handleErrorClose"/>
  </div>
</template>

<script>
// Vuex
import {mapGetters, mapMutations} from 'vuex'

export default {
  name: 'InventoryProcessContent',
  emits: ['reset', 'block'],
  props: {
    barcode: {
      type: Object,
      default () {
        return {}
      }
    },
    queues: {
      type: Array,
      default () {
        return []
      }
    },
    place: {
      type: [String, Number],
      default () {
        return null
      }
    }
  },
  data () {
    return {
      queue: null,
      sequence: null,
      quantity: 1,
      objects: {},
      data: {
        objects: [
          ...this.queues,
          {
            type: 'message',
            data: {
              position: 'bottom',
              text: 'Choose queue'
            }
          }
        ]
      },
      error: '',
      document: '',
      offerId: '',
      barcodeID: '',
      lastScannedPlace: null
    }
  },
  computed: {
    ...mapGetters([
      'terminalContainerHeight'
    ]),
    tilesSchema () {
      return [
        {
          id: 1,
          label: this.$t('Reset'),
          icon: 'refresh',
          value: true,
          onChanged: () => {
            this.handleReset()
          }
        },
        {
          id: 2,
          label: this.$t('Choose Queue'),
          icon: 'grading',
          value: true,
          onChanged: () => {
            this.handleBackToQueues()
          }
        },
        {
          id: 3,
          label: this.$t('Complete location'),
          icon: 'check',
          value: true,
          onChanged: () => {
            this.handleClick({
              event: 'storage.inventory.location.complete', data: {
                id: this.lastScannedPlace
              }
            })
          }
        },
        {
          id: 4,
          label: this.$t('Complete inventory'),
          icon: 'done_all',
          value: true,
          onChanged: () => {
            const description = this.$t("You requested to finish whole revision wave, not just one location. Are you sure you want to close all current tasks and complete the wave?")
            this.$refs.confirmModal.show({ description })
              .then(isOk => {
                if (!isOk) {
                  return
                }
                this.handleClick({ event: 'storage.inventory.complete' })
              })
          }
        }

        // {
        //   id: 3,
        //   label: this.$t('Skip Task'),
        //   icon: 'skip_next',
        //   value: true,
        //   onChanged: () => {
        //     this.handleClick({ event: 'storage.queue.task.reject' })
        //   }
        // }
      ]
    }
  },
  watch: {
    barcode (newVal) {
      this.handleBarcode(newVal)
    },
  },
  methods: {
    ...mapMutations([
      'addErrorNotification'
    ]),
    handleBack () {
      this.$router.push('/terminal')
    },
    handleReset () {
      this.$emit('reset')
    },
    handleBackToQueues () {
      this.objects = {}
      this.data = {
        objects: [
          ...this.queues,
          {
            type: 'message',
            data: {
              position: 'bottom',
              text: 'Choose queue'
            }
          }
        ]
      }
    },
    handleErrorClose () {
      this.error = ''
    },
    handleClick (item) {
      if (!item.event) {
        return
      }

      const events = {
        focus: () => {
          this.$emit('block')
        },
        focusOut: () => {
          this.$emit('block')
        },
        quantityChange: () => {
          this.quantity = item.data.quantity
        },
        counterSubmit: () => {
          this.quantity = item.data.quantity
          if( item.data.barcodeID) {
            this.barcodeID = item.data.barcodeID
          }
          this.handleBarcode({ raw: item.data.barcode, value: item.data.barcode })
        },
        'storage.tasks.queue.opened': () => {
          this.queue = item.data
          this.data.entityClass = item.type
          this.data.event = item.event
          this.sequence = item.sequence

          this.handleBarcode({ value: item.data.id, raw: item.data.id, type: '' })
        },
        'storage.tasks.task.offer.opened': () => {
          if (item.data) {
            this.offerId = item.data.id
          } else {
            this.offerId = item.id
          }
          this.data.entityClass = item.type
          this.handleBarcode({ value: (item.barcodes || [])[0], raw: (item.barcodes || [])[0], type: '' })
        },
        'storage.queue.task.reject': () => {
          this.data.event = 'storage.queue.task.reject'
          this.data.entityClass = this.$entities.Orderadmin_Storage_Entity_Picking_Task

          this.handleBarcode({ value: '', raw: '', type: '' })
        },
        'storage.document.opened': () => {
          this.data.event = item.event
          this.data.entityClass = item.entityClass

          this.handleBarcode({ value: item.data.id, raw: item.data.id, type: '' })

        },
        'storage.place.opened': () => {
          this.data.event = item.event
          this.data.entityClass = item.entityClass
          const raw = `S/P/${item.data.id}*`
          this.handleBarcode({ value: item.data.id, raw: raw, type: '' })
        },
        'storage.tasks.task.batch.opened': () => {
          this.data.batch = item.data
          this.handleBarcode(this.barcode)
        },
        // {"timestamp":"2022-03-29T14:09:34.432Z","basket":"137","entity":"S/D/87*","event":"storage.inventory.complete","entityClass":"Orderadmin\Storage\Entity\Movement\Inventory","document":87}
        // handleBarcode ()
        'storage.inventory.complete': () => {
          if (!this.barcode) {
            this.addErrorNotification('Barcode is empty!')
            return
          }
          this.data.event = 'storage.inventory.complete'
          this.data.entityClass = this.$entities.Orderadmin_Storage_Entity_Movement_Inventory
          this.handleBarcode({ value: '', raw: '', type: '' })
        }
      }

      if (events[item.event]) {
        return events[item.event]()
      }

      this.data.entityClass = item.type
      this.data.event = item.event

      this.handleBarcode({ value: item.data.id, raw: item.data.id, type: '' })
    },
    handleBarcode (barcode) {
      if (this.data.requiredData && this.data.requiredDataType) {
        const validations = {
          regexp: (value, validation) => {
            const regex = new RegExp(validation)
            return !regex.test(value.raw)
          },
          text: (value, validation) => {
            return value.raw !== validation
          }
        }

        if (!validations[this.data.requiredDataType]) {
          return this.addErrorNotification('Validation is not recognized!')
        }

        if (validations[this.data.requiredDataType](barcode, this.data.requiredData)) {
          return this.addErrorNotification('Barcode does not match scheme!')
        }
      }

      const taskColName = this.getCollectionName(this.$entities.Orderadmin_Storage_Entity_Picking_Task)

      let data = this.reduceData(
          [
            'objects',
            'queue',
            'requiredData',
            'requiredDataType',
            'maxCount',
            'entity',
            'timestamp'
          ],
          {
            timestamp: new Date().toISOString(),
            basket: this.place,
            entity: barcode.raw
          }
      )

      if (this.data.event === 'storage.tasks.task.offer.opened' && this.offerId) {
        data.offer = this.offerId
        this.offerId = ''
      }

      if(this.data.event === 'storage.tasks.task.offer.opened' && this.barcodeID) {
        data.barcode = this.barcodeID
        this.barcodeID = ''
      }

      if (data.entityClass === 'Orderadmin\\Storage\\Entity\\Tasks\\Sequence') {
        this.sequence = data.entity
      }

      if (this.sequence && !this.data.sequence) {
        data.sequence = this.sequence
      }

      if (this.data.event === 'storage.tasks.task.offer.opened' || this.data.event === 'storage.tasks.scan.item') {
        data.quantity = this.quantity
      }

      if (this.objects[taskColName]) {
        data.task = this.objects[taskColName].data.map(({ id }) => id)
      }

      const task = this.objects[this.$entities.Orderadmin_Storage_Entity_Picking_Task]
          ? { ...this.objects[this.$entities.Orderadmin_Storage_Entity_Picking_Task] }
          : null

      if (!data.task && task) {
        data.task = task.data.id
      }

      if (this.objects[this.$entities.Orderadmin_Storage_Entity_Item]) {
        data.item = this.objects[this.$entities.Orderadmin_Storage_Entity_Item].data.id
      }

      const place = this.getPlaceObject()

      if (place) {
        data.place = place.data.id
      }

      if (this.objects[this.$entities.Orderadmin_Storage_Entity_Movement_Inventory]) {
        data.document = this.objects[this.$entities.Orderadmin_Storage_Entity_Movement_Inventory].data.id
        this.document = this.objects[this.$entities.Orderadmin_Storage_Entity_Movement_Inventory].data.id
      } else if (this.document) {
        data.document = this.document
      }

      if (this.objects[this.$entities.Barcode]) {
        data.entity = this.objects[this.$entities.Barcode].data
      }

      if (this.objects[this.$entities.Batch] && !Array.isArray(this.objects[this.$entities.Batch].data)) {
        data.batch = this.objects[this.$entities.Batch].data
      }

      if (this.objects['callback-data']) {
        data = { ...this.objects['callback-data'].data, ...data }
      }

      if (barcode.type === 'S/P' || this.data.event === 'storage.place.opened') {
        this.lastScannedPlace = barcode.value
      }

      if (!this.queue.id) {
        return this.addErrorNotification('Queue not selected!')
      }

      return this.$service.picking.process(this.queue.id, data)
          .then(data => {
            this.objects = data.objects.reduce((acc, obj) => {
              const key = obj.type === 'collection'
                  ? `collection:${obj.entityClass}`
                  : obj.type

              acc[key] = obj

              return acc
            }, {})

            this.data = data
            this.quantity = 1
          })
          .catch(err => {
            this.error = err.message
          })
    },
    getPlaceObject () {
      const entity = this.$entities.placeObjects.find(x => this.objects[x])
      return this.objects[entity]
    },
    getPlaceCollection () {
      const entity = this.$entities.placeObjects.find(x => this.objects[this.getCollectionName(x)])
      return this.objects[this.getCollectionName(entity)]
    },
    getCollectionName (entity) {
      return `collection:${entity}`
    },
    reduceData (ignoredKeys = [], defaultValue = {}) {
      return Object.keys(this.data)
          .filter(key => !ignoredKeys.includes(key))
          .reduce((acc, key) => {
            if (!this.data[key]) {
              return acc
            }

            return {
              ...acc,
              [key]: typeof this.data[key] === 'object' && !Array.isArray(this.data[key])
                  ? this.data[key].id
                  : this.data[key]
            }
          }, { ...defaultValue })
    }
  }
}
</script>
